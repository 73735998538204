import { render, staticRenderFns } from "./cal-main-carousel.vue?vue&type=template&id=014a9b54"
import script from "./cal-main-carousel.vue?vue&type=script&lang=js"
export * from "./cal-main-carousel.vue?vue&type=script&lang=js"
import style0 from "./cal-main-carousel.vue?vue&type=style&index=0&id=014a9b54&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CalMainSlide: require('/builds/callia-inc/callia-storefront-ui/components/cal-main-slide.vue').default})
