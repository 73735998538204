export default function ({ route, redirect, app }) {
    const locale = app.i18n.getLocaleCookie();
    const redirectMappings = [
        { from: 'wedding', to: 'wedding-flowers'},
        { from: 'weddings', to: 'wedding-flowers'},
        { from: `occasions/${route.path.split('occasions/')[1]}`, to: `occasion/${route.path.split('occasions/')[1]}`},
        { from: 'wedding-flowers/final-invoice-confirmation', to: 'final-invoice-confirmation'},
        { from: `wedding-flowers/final-invoice-confirmation/?recordId=${route.query.recordId}`, to: `final-invoice-confirmation/?recordId=${route.query.recordId}`},
        { from: 'occasion/mothers-day', to: 'collection'},
        { from: 'occasion/mothers-day-pandora', to: 'collection'}
        // Add more redirection mappings as needed
    ]
    const matchedRoute = redirectMappings.find(mapping => `/${locale}/${mapping.from}` === route.path || `/${mapping.from}` === route.path || `/${locale}/${mapping.from}` === route.fullPath);
    if (matchedRoute) {
        redirect(`/${locale}/${matchedRoute.to}`);
    }
}